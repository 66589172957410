async function initSwiper() {
	const SwiperInstance = await import(
		"./swiper"
	);
	const Swiper = SwiperInstance.Swiper;
	const Navigation = SwiperInstance.Navigation;

	// Carousel
	const swiperCarouselElements = document.getElementsByClassName(
		"horizontal-carousel"
	);
	for (const element of swiperCarouselElements) {
		const swiper = new Swiper("#" + element.getAttribute("id"), {
			slidesPerView: "auto",
			spaceBetween: 5,
			lazy: true,
			lazyPreloadPrevNext: 1,
			modules: [Navigation],
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			}
		});
	}
}

document.addEventListener("DOMContentLoaded", async function () {
	await initSwiper();
});

function scrollToForm() {
  var $form = $('.sale-form');
  if (!$form) {
    return;
  }

  $("html, body").animate(
    { scrollTop: $form.offset().top - 165 },
    300
  );
}

function slideOpenForm() {
  var $form = $('.sale-form');
  if (!$form) {
    return;
  }

  $form.slideDown("slow");
  scrollToForm();
}

(function ($) {
	$(window).load(function () {
		// owl-carousel
		$(".owl-carousel:not(.slider)").owlCarousel({
			items: 1,
			loop: false,

			// autoplay
			autoplay: false,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: false,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: false,
			touchDrag: false
		});

		// slider
		$(".slider:not(.reviews-overview)").owlCarousel({
			items: 1,
			margin: 15,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		// slider
		$(".slider.reviews-overview").owlCarousel({
			items: 1,
			margin: 30,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: false,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				768: {
					items: 2,
				},
			},
		});

		// form-sent
		setTimeout(() => {
			var locationSearch = "" + document.location.search;
			if (
				(-1 === locationSearch.indexOf("form[sent]") &&
					-1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
				-1 === document.location.hash.indexOf("#form")
			) {
				return;
			}

      var $form = $('.sale-form');
      if ($form.length) {
        slideOpenForm();
        scrollToForm();
        return;
      }

			var $headerHeight = $(".header");
			var $message = $(".form-success-message");

			if (!$headerHeight.length || !$message.length) {
				return;
			}

			var currentScrollTop = $("html").scrollTop();
			var newScrollTop =
				$message.offset().top - $headerHeight.outerHeight() - 30;
			if (newScrollTop >= currentScrollTop) {
				return;
			}

			$("html, body").animate({
				scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
			});
		}, 500);
	});

	$(document).ready(function () {
		// sticky
		// require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

		// on mobile
		if ($(window).width() < 578) {
			$("body").add(".header").addClass("sticky");
		}
		// on all pages but not home
		if (!$("body").hasClass("home")) {
			$("body").add(".header").addClass("sticky");
		}

		$(window).scroll(function () {
			const $el = $("body").add(".header");
			// on large screens
			// on homepage
			if ($("body").hasClass("home")) {
				const stickyStartPoint = $(".eyecatcher.large").outerHeight();

				if ($(window).width() > 578) {
					if ($(window).scrollTop() > stickyStartPoint) {
						$el.addClass("sticky");
					} else {
						$el.removeClass("sticky");
						$("#default_menu").removeClass("show");
						$(".navbar-toggler").attr("aria-expanded", "false");
					}
				}
			}
		});

		// lead section in assortiment-details page
		if ($("body").hasClass("assortiment-detail")) {
			const stickyStartPoint = $(".lead-section").offset().top;

			$(window).scroll(function () {
				if ($(window).scrollTop() > stickyStartPoint) {
					if (!$(".lead-section").hasClass("sticky")) {
						const cloned = $(".lead-section").clone().addClass("cloned sticky");
						$(".main").append(cloned);
					}
				} else {
					$(".lead-section.cloned").remove();
				}
			});
		}

		// toggle assortiment collapse item
		$(".collapse-toggle-item-link").on("click", function (e) {
			e.preventDefault();
			$(this).next(".collapse-toggle-item-content").slideToggle();
		});

		// stop click behavior on # links
		$(document).on("click", 'a[href^="#"]', function (e) {
			e.preventDefault();

			let identifier = $(this).attr("href");

			if (identifier === "#") {
				return;
			}

			if (identifier === "#sale-form") {
        slideOpenForm();
        scrollToForm();
			}
		});

		// open share icons
		$(document).on("click", ".share-information > a", function (e) {
			$(this).next(".platforms-list").toggleClass("open");
		});

		// fancybox
		// fancybox - options
		const fancyboxOptions = {
			buttons: [
				// "zoom",
				// "share",
				// "download",
				"slideShow",
				"fullScreen",
				"thumbs",
				"close",
			],

			protect: true,

			// open / close animation
			animationDuration: 500,
			animationEffect: "zoom",

			// slide item transition
			transitionEffect: "slide",
			transitionDuration: 500,

			btnTpl: {
				close:
					'<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
					'<svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_707_15)"><path d="M11.3765 12L16.0687 7.30776C16.6453 6.7312 16.6453 5.79839 16.0687 5.22183L15.0281 4.1812C14.4515 3.60464 13.5187 3.60464 12.9421 4.1812L8.24995 8.87339L3.55776 4.1812C2.9812 3.60464 2.04839 3.60464 1.47183 4.1812L0.431201 5.22651C-0.145361 5.80308 -0.145361 6.73589 0.431201 7.31245L5.12339 12L0.431201 16.6921C-0.145361 17.2687 -0.145361 18.2015 0.431201 18.7781L1.47183 19.8187C2.04839 20.3953 2.9812 20.3953 3.55776 19.8187L8.24995 15.1265L12.9421 19.8187C13.5187 20.3953 14.4515 20.3953 15.0281 19.8187L16.0687 18.7781C16.6453 18.2015 16.6453 17.2687 16.0687 16.6921L11.3765 12Z" fill="black"/></g><defs><clipPath id="clip0_707_15"><rect width="16.5" height="24" fill="white"/></clipPath></defs></svg>' +
					"</button>",

				thumbs:
					'<button data-fancybox-thumbs class="fancybox-button fancybox-button--thumbs" title="{{THUMBS}}">' +
					'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.875 1.5H22.875C23.4984 1.5 24 2.00156 24 2.625V10.125C24 10.7484 23.4984 11.25 22.875 11.25H13.875C13.2516 11.25 12.75 10.7484 12.75 10.125V2.625C12.75 2.00156 13.2516 1.5 13.875 1.5ZM10.125 1.5H1.125C0.501562 1.5 0 2.00156 0 2.625V10.125C0 10.7484 0.501562 11.25 1.125 11.25H10.125C10.7484 11.25 11.25 10.7484 11.25 10.125V2.625C11.25 2.00156 10.7484 1.5 10.125 1.5ZM0 13.875V21.375C0 21.9984 0.501562 22.5 1.125 22.5H10.125C10.7484 22.5 11.25 21.9984 11.25 21.375V13.875C11.25 13.2516 10.7484 12.75 10.125 12.75H1.125C0.501562 12.75 0 13.2516 0 13.875ZM13.875 22.5H22.875C23.4984 22.5 24 21.9984 24 21.375V13.875C24 13.2516 23.4984 12.75 22.875 12.75H13.875C13.2516 12.75 12.75 13.2516 12.75 13.875V21.375C12.75 21.9984 13.2516 22.5 13.875 22.5Z" fill="black"/></svg>' +
					"</button>",

				fullScreen:
					'<button data-fancybox-fullscreen class="fancybox-button fancybox-button--fsenter" title="{{FULL_SCREEN}}">' +
					'<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_707_11)"><path d="M0 8.4375V2.625C0 2.00156 0.496051 1.5 1.11264 1.5H6.86126C7.16724 1.5 7.41758 1.75313 7.41758 2.0625V3.9375C7.41758 4.24687 7.16724 4.5 6.86126 4.5H2.96703V8.4375C2.96703 8.74687 2.71669 9 2.41071 9H0.556319C0.250343 9 0 8.74687 0 8.4375ZM13.3516 2.0625V3.9375C13.3516 4.24687 13.602 4.5 13.908 4.5H17.8022V8.4375C17.8022 8.74687 18.0525 9 18.3585 9H20.2129C20.5189 9 20.7692 8.74687 20.7692 8.4375V2.625C20.7692 2.00156 20.2732 1.5 19.6566 1.5H13.908C13.602 1.5 13.3516 1.75313 13.3516 2.0625ZM20.2129 15H18.3585C18.0525 15 17.8022 15.2531 17.8022 15.5625V19.5H13.908C13.602 19.5 13.3516 19.7531 13.3516 20.0625V21.9375C13.3516 22.2469 13.602 22.5 13.908 22.5H19.6566C20.2732 22.5 20.7692 21.9984 20.7692 21.375V15.5625C20.7692 15.2531 20.5189 15 20.2129 15ZM7.41758 21.9375V20.0625C7.41758 19.7531 7.16724 19.5 6.86126 19.5H2.96703V15.5625C2.96703 15.2531 2.71669 15 2.41071 15H0.556319C0.250343 15 0 15.2531 0 15.5625V21.375C0 21.9984 0.496051 22.5 1.11264 22.5H6.86126C7.16724 22.5 7.41758 22.2469 7.41758 21.9375Z" fill="black"/></g><defs><clipPath id="clip0_707_11"><rect width="20.7692" height="24" fill="white"/></clipPath></defs></svg>' +
					'<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.4375 9H14.625C14.0016 9 13.5 8.49844 13.5 7.875V2.0625C13.5 1.75313 13.7531 1.5 14.0625 1.5H15.9375C16.2469 1.5 16.5 1.75313 16.5 2.0625V6H20.4375C20.7469 6 21 6.25313 21 6.5625V8.4375C21 8.74687 20.7469 9 20.4375 9ZM7.5 7.875V2.0625C7.5 1.75313 7.24687 1.5 6.9375 1.5H5.0625C4.75313 1.5 4.5 1.75313 4.5 2.0625V6H0.5625C0.253125 6 0 6.25313 0 6.5625V8.4375C0 8.74687 0.253125 9 0.5625 9H6.375C6.99844 9 7.5 8.49844 7.5 7.875ZM7.5 21.9375V16.125C7.5 15.5016 6.99844 15 6.375 15H0.5625C0.253125 15 0 15.2531 0 15.5625V17.4375C0 17.7469 0.253125 18 0.5625 18H4.5V21.9375C4.5 22.2469 4.75313 22.5 5.0625 22.5H6.9375C7.24687 22.5 7.5 22.2469 7.5 21.9375ZM16.5 21.9375V18H20.4375C20.7469 18 21 17.7469 21 17.4375V15.5625C21 15.2531 20.7469 15 20.4375 15H14.625C14.0016 15 13.5 15.5016 13.5 16.125V21.9375C13.5 22.2469 13.7531 22.5 14.0625 22.5H15.9375C16.2469 22.5 16.5 22.2469 16.5 21.9375Z" fill="black"/></svg>' +
					"</button>",

				slideShow:
					'<button data-fancybox-play class="fancybox-button fancybox-button--play" title="{{PLAY_START}}">' +
					'<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.8937 10.0626L3.39375 0.307872C2.05313 -0.484315 0 0.284435 0 2.24381V21.7485C0 23.5063 1.90781 24.5657 3.39375 23.6844L19.8937 13.9344C21.3656 13.0672 21.3703 10.9297 19.8937 10.0626Z" fill="black"/></svg>' +
					'<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75 21H2.25C1.00781 21 0 19.9922 0 18.75V2.25C0 1.00781 1.00781 0 2.25 0H6.75C7.99219 0 9 1.00781 9 2.25V18.75C9 19.9922 7.99219 21 6.75 21ZM21 18.75V2.25C21 1.00781 19.9922 0 18.75 0H14.25C13.0078 0 12 1.00781 12 2.25V18.75C12 19.9922 13.0078 21 14.25 21H18.75C19.9922 21 21 19.9922 21 18.75Z" fill="black"/></svg>' +
					"</button>",

				// Arrows
				arrowLeft:
					'<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
					'<div><svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.61709 10.2032L9.72959 1.09536C10.1702 0.654736 10.8827 0.654736 11.3187 1.09536L12.3827 2.15942C12.8233 2.60005 12.8233 3.30786 12.3827 3.74849L5.16396 11L12.3827 18.2563C12.8187 18.6969 12.8187 19.4047 12.3827 19.8454L11.3187 20.9094C10.878 21.35 10.1655 21.35 9.72959 20.9094L0.61709 11.7969C0.176465 11.3563 0.176465 10.6438 0.61709 10.2079V10.2032Z" fill="black"/></svg></div>' +
					"</button>",

				arrowRight:
					'<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
					'<div><svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3827 12.7969L4.27021 21.9047C3.82959 22.3454 3.11709 22.3454 2.68115 21.9047L1.61709 20.8407C1.17646 20.4 1.17646 19.6922 1.61709 19.2516L8.83584 12L1.61709 4.74849C1.18115 4.30786 1.18115 3.60005 1.61709 3.15942L2.68115 2.09536C3.12178 1.65474 3.83428 1.65474 4.27021 2.09536L13.3827 11.2032C13.8233 11.6438 13.8233 12.3563 13.3827 12.7922V12.7969Z" fill="black"/></svg></div>' +
					"</button>",
			}
		};

		$("a[data-fancybox]").fancybox(fancyboxOptions);

		// open fancybox thumbs when clicking on 'Show all' btn
		$("#fancybox-thumbs").on("click", function (e) {
			e.preventDefault();

			$.fancybox.open(
				$("a[data-fancybox]"),
				Object.assign(
					{
						thumbs: {
							autoStart: true, // Display thumbnails on opening
						},
					},
					fancyboxOptions
				)
			);
		});

		// close fancybox thumbs when clicking on 'Show all' btn
		$(document).on(
			"click",
			".fancybox-thumbs .fancybox-thumbs__list a",
			function (e) {
				e.preventDefault();
				var itemIndex = $(this).attr("data-index");

				$.fancybox.getInstance($("a[data-fancybox]")).Thumbs.toggle();
				$.fancybox.getInstance($("a[data-fancybox]")).jumpTo(itemIndex);
			}
		);

		// scroll-to-content
		$(".js-3w-scroll-to-content").on("click", function (event) {
			event.preventDefault();
			var identifier = $(this).attr("data-target");
			var $element = $(identifier);

			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top }, 500);
			}
		});

		// action on menu-toggler clicked
		$(".menu-toggle .navbar-toggler").on("click", function (e) {
			const stickyStartPoint = $(".eyecatcher.large").outerHeight();
			// check is not mobile
			if ($(window).width() > 578) {
				// check if the header not sticky is
				if ($(window).scrollTop() < stickyStartPoint) {
					// check if the open is
					if ($(this).attr("aria-expanded") === "true") {
						$("body").add(".header").removeClass("sticky");
					} else {
						$("body").add(".header").addClass("sticky");
					}
				}
			}
		});

		// prevent scrolling on #TommyBookingSupport page
		$(window).on("hashchange", function (e) {
			if ($("#TommyBookingSupport").length > 0) {
				e.preventDefault();

				// disable scrolling
				$("body").css("overflow", "hidden");

				// set current scroll position
				$("html, body").animate({
					scrollTop: $("#TommyBookingSupport").offset().top - 120,
				});

				// enable scrolling
				setTimeout(() => {
					$("body").css("overflow", "auto");
				}, 10);
			}
		});

		// page loader
		setTimeout(function () {
			$(".page-loader").addClass("active");
			$(".page-loader.active").on(
				"webkitTransitionEnd transitionend",
				function (e) {
					$(this).addClass("hide");
					$(".eyecatcher .owl-carousel .owl-item").addClass("animate");
				}
			);
		}, 1000);
	});
})(jQuery);
